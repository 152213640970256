<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  mounted(){
    document.fonts.ready.then(() => {
      this.$store.commit('setFontLoading', false);
    });
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f5f7f9;
}
@font-face {
  font-family: 'SourceHanSerifCN';
  src: url('https://gtgs-web-static-files.oss-cn-north-2-gov-1.aliyuncs.com/fonts/SourceHanSerifCN-Medium.WOFF');
}
  ::-webkit-scrollbar {
    display:none;
  }

</style>
